// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-modal .checkbox-input .ant-checkbox-input {
  width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/admin-module/Challenges/existing-feature-task/existing-feature-task.scss"],"names":[],"mappings":"AAEQ;EACI,sBAAA;AADZ","sourcesContent":[".task-modal{\n    .checkbox-input{\n        .ant-checkbox-input{\n            width: auto !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
