import React, { useContext, useState } from "react";
import { deleteItemTraining, getDataTrainingCoach, jsonPostDataTraining } from "../util/coach-api-calls";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/user-context";
export const ExistingWorkoutContext = React.createContext({});
export const ExistingWorkoutContextProvider = ({ children = {} }) => {
  const {selectedSport} = useContext(UserContext)
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const location = useLocation();
  let crumbArray = location.pathname.split("/").filter((crumb) => crumb !== "");
  let currentGroupName = crumbArray[crumbArray.length - 1];  
  const [existingWorkouts, setExistingWorkouts] = useState([]);
  const [workoutTasks, setWorkoutTasks] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [updateTemplate, setUpdateTemplate] = useState(false);
  const [selectedWorkoutTasks, setSelectedWorkoutTasks] = useState([]);
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [createdWorkout, setCreatedWorkout] = useState({
  });
  const [membersView, setMembersView] = useState(false);
  const [skillLevel, setSkillLevel] = useState("beginner,intermediate,advanced");
  const [taskType, setTaskType] = useState("ballogy,external,group");
  const [sort, setSort] = useState("popular");
  const [privacy , setPrivacy] = useState(false)
  const [privacyModal, setPrivacyModal] = useState(false);
  const [current, setCurrent] = useState();
  const [search , setSearch] = useState("")
  const [loading , setLoading] = useState(false)
  const [fWLoading  , setFWLoading] = useState(false)
  const [allFeaturedWorkouts , setAllFeaturedWorkouts] = useState([])
  const [totalWorkouts , setTotalWorkouts] = useState(0);
  const [offset, setOffset] = useState(0);
  const createExistingWorkout = (update_template , curriculum_group) => {
    var x = selectedWorkoutTasks.map(function (item , index) {
      let tags =
        typeof item.tags === "object" ? item.tags.map((a) => a.tag) : item.tags;
      return {
        lock: current === index || index > current || item.lock === true && index !== 0 ? true :  false,
        id: item.id,
        title: item.title,
        is_public: item.is_public,
        type: item.type,
        order: item.order,
        // lock : item.lock,
        media_url: item.media_url,
        media_uri: item.media_uri,
        media_thumbnail: item.media_thumbnail,
        image: item.image,
        media_type: item.media_type,
        media_required: item.media_required,
        instruction: item.instruction,
        best_score: item.best_score,
        skill_level: item.skill_level,
        is_time_limit: item.is_time_limit,
        time_limit_seconds: item.time_limit_seconds,
        score_based_test: item.score_based_test,
        num_completion: isNaN(item.num_completion) ? 1 : item.num_completion,
        legacy_point: item.legacy_point,
        allow_more_reps: item.allow_more_reps,
        group_id: params.id ,
        group_name: decodeURI(currentGroupName),
        tags: typeof tags === "object" ? tags.toString().toLowerCase() : tags.toLowerCase(),
        status: item.status,
        taskgroup_id: item.taskgroup_id,
        library_taskgroup_id: item.library_taskgroup_id,
        task_id: item.task_id,
        is_cw : curriculum_group
      };
    });
    let data = {
      id: workoutTasks.id,
      title:title,
      type: workoutTasks.type,
      is_public: privacy,
      group_id:params.id ,
      group_name: decodeURI(currentGroupName),
      taskgroup_id: workoutTasks.taskgroup_id,
      create_existing: true,
      update_template: update_template,
      list_tasks: x,
      is_cw:curriculum_group
    };
    jsonPostDataTraining(`existing/workout`, data)
      .then((res) => {
        setCreatedWorkout(res.data);
        setNewTaskArray((newTaskArray) => []);
        setMembersView(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllWorkouts = () => {
    setLoading(true)
    let url = ""
    if(skillLevel.length === 0){
      url = `admin/workout?group_id=${params.id}&sort_by=${sort}&tag=${selectedCategory}&workout_filter=${taskType}&search=${search}`
    }else{
      url = `admin/workout?skill_level=${skillLevel}&group_id=${params.id}&sort_by=${sort}&tag=${selectedCategory}&workout_filter=${taskType}&search=${search}`
    }
    getDataTrainingCoach(url)
      .then((res) => {
        // if (res && res.status === 200) {
          setExistingWorkouts(res.data);
        // }
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };
  const deleteTask = (id) => {
    deleteItemTraining(`library/task/${id}`)
      .then((res) => {
        if(res && res.status === 200){
        const updatedArray = selectedWorkoutTasks.filter((item) => item.id !== id);
        setSelectedWorkoutTasks(updatedArray)
        message.success({
          content:"Task deleted successfully"
        })
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllFeatureWorkouts = () => {
    setFWLoading(true);
    getDataTrainingCoach(
      `all/feature/workout?sports_id=${selectedSport}&group_id=${params.id}`
    )
      .then((res) => {
        setAllFeaturedWorkouts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFWLoading(false);
      });
  };
  React.useEffect(() => {
    if(selectedCategory !== "featured"){
      setExistingWorkouts([])
      setExistingWorkouts(existingWorkouts=>[])
    const delayDebounceFn = setTimeout(() => {
      getAllWorkouts();
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    }
  }, [skillLevel, taskType, sort, selectedCategory, existingTaskModal,search]);
  const defaultContext = {
    skillLevel,
    existingWorkouts,
    workoutTasks,
    setWorkoutTasks,
    deleteTask,
    params,
    setSelectedCategory,
    setError,
    sort,
    setTitle,
    error,
    title,
    setExistingTaskModal,
    existingTaskModal,
    setNewTaskModal,
    newTaskModal,
    updateTemplate,
    setUpdateTemplate,
    createExistingWorkout,
    selectedWorkoutTasks,
    setSelectedWorkoutTasks,
    createdWorkout,
    newTaskArray,
    membersView,
    setMembersView,
    setSkillLevel,setTaskType,setSort,privacy,setPrivacy,privacyModal , setPrivacyModal,
    current , setCurrent,
    search,
    setSearch,
    setExistingWorkouts,
    loading,
    taskType,
    selectedCategory,
    allFeaturedWorkouts,
    setAllFeaturedWorkouts,
    getAllFeatureWorkouts,
    fWLoading,
    sort
  };
  return (
    <ExistingWorkoutContext.Provider value={defaultContext}>
      {children}
    </ExistingWorkoutContext.Provider>
  );
};
