import React, { useEffect, useState } from "react";
import { message, Form, Button, Modal, Select, Dropdown, Menu } from "antd";
import { getDataTest, jsonPostDataTest } from "../../util/api-calls";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;
export const SubmissionDetailContext = React.createContext({});
export const SubmissionDetailContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const [exercises, setExercises] = useState([]);
  const [exitButton , setExitButton] = useState(false)
  const [clearButton , setClearButton] = useState(false)
  const [createdTest, setCreatedTest] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [playerMessage, setPlayerMessage] = useState("");
  const [reportSendDisable, setReportSendDisable] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");
  const [assigneeModal, setAssigneeModal] = useState(false);
  const [visionData , setVisionData] = useState({})
  const [videoReview , setVideoReview] = useState(false)
  const [actionButton , setActionButton ] = useState(false)
  const [tableData , setTableData] = useState([])
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const [loading, setLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState();
  const postResult = () => {
    setLoading(true)
    let data = {
      message: playerMessage,
      completion_time: selectedTest.video_duration,
      test_status: "6",
      athlete_test_id: selectedTest.athlete_test,
    };
    jsonPostDataTest(`test/attempt/update/`, data)
      .then((res) => {
        navigate("/home/submissions");
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };
  const getExercises = () => {
    getDataTest(`test/${params.id}/exercise/list/`)
      .then((res) => {
        setExercises(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getExercises();
  }, []);
  const saveVideoReview = (length) => {
    setLoading(true)
    var x =
      exerciseData.length > 0 &&
      exerciseData.filter((item)=>item.primary_action !== "added").map(function (item, index) {
        return {
          // exercise_detail: item.shot_count,
          exercise_detail : index + 1,
          primary_action: item.primary_action,
          shot_start_time: item.shot_start_time,
          shot_end_time: item.shot_end_time,
          score : item.primary_action === 2 ? 0 : index + 1 <= 2 || index + 1 >=  38 ? 1 : index + 1 >= 13 && index + 1 <= 37 ? 3 :  2, 
          timestamp : item.timestamp
        };
      });
    let data = {
      completion_time: selectedTest.video_duration,
      exercises: length === 0 ? [] : x,
      test_status: "2",
      athlete_test_id: selectedTest.athlete_test,
    };
    jsonPostDataTest(`test/attempt/save/`, data)
      .then((res) => {
        setCreatedTest(res.data.detail);
        setReportSendDisable(true);
        setExitButton(false)
        if(clearButton === false){
        setClearButton(true)
        }else{
          // setExerciseData(exerciseData=>[])
          setClearButton(false)

        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(()=>{
        setLoading(false)
      })
  };
  const getTestData = () => {
    setLoading(true);
    getDataTest(`admin/pending/task/${params.id}/detail/`)
      .then((res) => {
        setSelectedTest(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getVisionDetail = () =>{
    getDataTest(`vision/test/attempt/${selectedTest?.athlete_test}/`).
    then((res)=>{
      setVisionData(res.data)
    }).catch((err)=>{
      console.log(err)
    })
  }
  const postInvalidReview = () => {
    let data = {
      message: invalidMessage,
      completion_time: selectedTest.video_duration,
      test_status: "3",
      athlete_test_id: selectedTest.athlete_test,
    };
    jsonPostDataTest(`test/attempt/update/`, data)
      .then((res) => {
        navigate("/home/submissions");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    selectedTest,
    getTestData,
    exercises,
    postResult,
    exerciseData,
    setExerciseData,
    createdTest,
    saveVideoReview,
    setPlayerMessage,
    setCreatedTest,
    loading,
    reportSendDisable,
    setReportSendDisable,
    setInvalidMessage,
    postInvalidReview,
    invalidMessage,
    clearButton , 
    setClearButton , 
    exitButton , 
    setExitButton,
    assigneeModal,
    setAssigneeModal,
    getVisionDetail,
    visionData,
    videoReview,
    setVideoReview,
    actionButton,
    setActionButton,
    tableData,
    setTableData,
    setVisionData,
    setLoading
  };
  return (
    <SubmissionDetailContext.Provider value={defaultContext}>
      {children}
    </SubmissionDetailContext.Provider>
  );
};
