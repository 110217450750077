import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tabs, Input, Button, Select, DatePicker, Dropdown, message } from "antd";
import "./task-home.scss";
import Plus from "../../../assets/images/plus.svg";
import Search from "../../../assets/images/search.svg";
// import history from "../../../util/history";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../coach-module/breadcrumbs/breadcrumb";
import axios from "axios";
import dayjs from "dayjs";
import { getDate, getDate1 } from "../../../util/config";
import Filter from "../../../assets/images/filter.svg";
import NewTaskModal from "../new-task-modal/new-task-modal";

// ../../assets/images/filter.svg";
import Calendar from "../../../assets/images/calendar.svg";

import { deleteItemTraining, getData } from "../../../util/api-calls";
import {
  TaskContext,
  TaskContextProvider,
} from "../../../context/task-context/task-context";
import DownArrow from "../../../assets/images/arrows.svg";
import DeleteTaskModal from "../delete-task/delete-task-modal";
import EditTask from "../../Groups/new-workout/edit-task-modal";
import EditTaskModal from "../edit-task-modal/edit-task-modal";
import TaskDetail from "../task-detail-modal/task-detail-modal";
import { UserContext } from "../../../context/user-context";

const { RangePicker } = DatePicker;

const TaskHomePage = () => {
  const {
    getGroupsList,
    pageNumber,
    pageSize,
    groups,
    totalGroups,
    handleTableChange,
    allTasks,
    getTasksList,
    setSort,
    loading,
    sort,
    setAllTasks,
    setSearch,
    search,
  } = useContext(TaskContext);
  const navigate = useNavigate();
  const {accountDetail , darkButtonBackground , selectedSport} = useContext(UserContext)
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [taskDetailModal, setTaskDetailModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const dateFormat = "ddd. MMMM D, YYYY";

  const onChange = (key) => {
    if (key === "delete") {
      setDeleteTaskModal(true);
    }
  };
  const tabItems = [
    {
      key: "all",
      label: `All`,
      //   children: `Content of Tab Pane 1`,
      // children: <Profile />
    },
    {
      key: "archived",
      label: `Archived`,
      // children: `Content of Tab Pane 2`,
      //   children: <UserAccountTable />
    },
  ];
  const items = [
    {
      key: "details",
      label: "View Details",
    },
    {
      key: "edit",
      label: "Edit Task",
    },
    {
      key: "delete",
      label: "Delete",
    },
    {
      key: "archive",
      label: "Archive",
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const convertSeconds = (sec) => {
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    if (minutes > 0 && seconds > 0) {
      return `${minutes} min  ${seconds} sec`;
    } else if (minutes > 0 && seconds <= 0) {
      return `${minutes} min`;
    } else if (seconds > 0) {
      return `${seconds} sec`;
    }
  };
  const onSearch = (value) => console.log(value);
  let new_array = allTasks.map(function (ele, index) {
    return { ...ele, temp_id: index + 1 };
  });
  useEffect(() => {
    if (editTaskModal === false) {
      setTimeout(() => {
        getTasksList();
      }, 1000);
    }
  }, [editTaskModal, sort, search]);
  const columns = [
    {
      title: "ID",
      dataIndex: "temp_id",
      key: "temp_id",
      width: 70,
    },
    {
      title: "Task NAME",
      dataIndex: "title",
      key: "title",
      width: 200,
      ellipsis: true,

    },
    {
      title: "type",
      dataIndex: "score_based_test",
      key: "score_based_test",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.score_based_test === true ? "Scored Task" : "Training Task"}
          </div>
        );
      },
    },
    // {
    //   title: "Sport",
    //   dataIndex: "sport_name",
    //   key: "sport_name",
    //   // render: (text, record, index) => {
    //   //   return <div key={index}>{record.sport['name']}</div>
    //   // },
    // },
    {
      title: "Time limit",
      dataIndex: "time_limit",
      key: "time_limit",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record.is_time_limit === true
              ? convertSeconds(record.time_limit_seconds)
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "author",
      dataIndex: "author",
      key: "author",
      ellipsis: true,
      render: (text, record, index) => {
        return <div key={index}>{record.group_name}</div>;
      },
    },
    // {
    //   title: "USED IN GROUPS",
    //   dataIndex: "used_in_group",
    //   key: "used_in_group",
    //   // render: (text, record, index) => {
    //   //   return <div key={index}>{record.sport['name']}</div>
    //   // },
    // },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      ellipsis: true,
      // width: 300,
    },
    {
      title: "ACTIONS",
      dataIndex: "acttion",
      // width: 100,
      render: (text, record, index) => (
        <Dropdown
          placeholder="Actions"
          className={"custom-dropdown " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
          menu={{
            items,

            onClick: ({ key }) => {
              if (key === "delete") {
                if(accountDetail.role !== null){
                for (let item of accountDetail.role?.role_permission) {
                  if(item.module.name === "Tasks" && item.permission.code_name === "DELETE"){
                    if(item.value === false){
                      info();
                    }
                    else{
                      setDeleteTaskModal(true);
                      setSelectedTask(record);                    
                    }
                  }
                }
              }else{
                setDeleteTaskModal(true);
                setSelectedTask(record);                    
              }
              } else if (key === "edit") {
                if(accountDetail.role !== null){
                for (let item of accountDetail.role?.role_permission) {
                  if(item.module.name === "Tasks" && item.permission.code_name === "EDIT"){
                    if(item.value === false){
                      info();
                    }
                    else{
                      setEditTaskModal(true);
                      setSelectedTask(record);                   
                    }
                  }
                }
              }
              else{
                setEditTaskModal(true);
                setSelectedTask(record);                   
              }
              } else if (key === "details") {
                setTaskDetailModal(true);
                setSelectedTask(record);
              } else {
                // setSelectedSport(record);
                // setEditSportModal(true);
              }
            },
          }}
          overlayClassName={"custom-dropdown-menu " + (selectedSport === 1 ? "orange-theme-dropdown" : "blue-theme-dropdown")}
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const gotoGroupDetail = (record) => {
    navigate({
      pathname: `./${record.name}`,
      search: `?id=${record.id}`,
      // search:`1`
    });
  };
  const info = () => {
    message.info("You don't have permissions. Contact to Admin");
  };
  const searchTask = (e) => {
    if (e.target.value.length >= 3) {
      // setPageNumber(1)
      setAllTasks([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllTasks((groups) => []);
      setSearch("");
    }
  };
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
  };
  const checkTaskPermission = () => {
    if(accountDetail?.role !== null){
    for (let item of accountDetail?.role?.role_permission) {
      if(item.module.name === "Tasks" && item.permission.code_name === "ADD"){
        if(item.value === false){
          info();
        }
        else{
          setNewTaskModal(true)        
        }
      }
    }
    }else{
      setNewTaskModal(true)        
    }
  }
  return (
    <div className="task-home-main">
      <div
        style={{
          display: "flex",
          position: "absolute",
          marginRight: "100px",
          right: 0,
          top: "101px",
        }}
      >
        {/* <div>
          <h1>Task Library</h1>
        </div>
       */}
        <Button
          icon={<img src={Plus} />}
          className="dark-base-btn"
          style={{ marginLeft: "auto" , background : darkButtonBackground }}
          onClick={checkTaskPermission}
        >
          Create Task
        </Button>
      </div>
      {/* <Tabs defaultActiveKey="1" items={tabItems} onChange={onChange} className={selectedSport === 1 ? "orange-theme" : "blue-theme"}/> */}
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchTask}
          />
          <div className="right-div">
            {/* <h4>Total Groups: </h4>
            <h4 style={{ fontWeight: "700" }}> {totalGroups}</h4> */}
            <Select
              placeholder="Newest"
              optionFilterProp="children"
              onChange={(e) => setSort(e)}
              suffixIcon={<img src={DownArrow} />}
              className="select-input"
              popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
              options={[
                {
                  value: "newest",
                  label: "Newest",
                },
                {
                  value: "oldest",
                  label: "Oldest",
                },
              ]}
            />
            {/* <RangePicker
              className="range-picker"
              format={dateFormat}
              suffixIcon={<img src={Calendar} />}
            />
            <button className="red-base-btn">
              <img src={Filter} alt="filter" />
            </button> */}
          </div>
        </div>
        <Table
          //    onRow={(record, rowIndex) => {
          //     return {
          //       onClick:() => gotoGroupDetail(record)
          //     };
          //   }}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          // rowSelection={rowSelection}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          loading={loading}
          columns={columns}
          //   dataSource={groups}
          // dataSource={allTasks}
          dataSource={new_array}
          className={"table-container " + (selectedSport === 1 ? "orange-theme" : "blue-theme")}
        />
      </div>
      <NewTaskModal
        newTaskModal={newTaskModal}
        setNewTaskModal={setNewTaskModal}
      />
      <DeleteTaskModal
        deleteTaskModal={deleteTaskModal}
        setDeleteTaskModal={setDeleteTaskModal}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
      />
      <EditTaskModal
        editTaskModal={editTaskModal}
        setEditTaskModal={setEditTaskModal}
        selectedTask={selectedTask}
      />
      {Object.keys(selectedTask).length > 0 && (
        <TaskDetail
          taskDetailModal={taskDetailModal}
          setTaskDetailModal={setTaskDetailModal}
          selectedTask={selectedTask}
        />
      )}
    </div>
  );
};

const TaskHome = () => {
  return (
    <TaskContextProvider>
      <TaskHomePage />
    </TaskContextProvider>
  );
};
export default TaskHome;
