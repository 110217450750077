import React, { useContext, useState, useRef, useEffect } from "react";
import { Button, Input, Modal, Select, Spin, Radio, Checkbox } from "antd";
import DownArrow from "../../../assets/images/arrows.svg";
import { getDate, getDateWithoutDay } from "../../../util/config";
import { getDataTrainingCoach } from "../../../util/coach-api-calls";
import Search from "../../../assets/images/search.svg";
import CategoryModal from "../../../admin-module/Groups/existing-workout/category-modal";
import EditTask from "./edit-task-modal";
import { UserContext } from "../../../context/user-context";
const ExistingTask = ({
  existingTaskModal,
  setExistingTaskModal,
  setNewTaskArray,
  newTaskArray,
  generalDetail,
}) => {
  const { selectedSport } = useContext(UserContext);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [skillLevel, setSkillLevel] = useState(
    "beginner,intermediate,advanced"
  );
  const [sort, setSort] = useState("popular");
  const [taskType, setTaskType] = useState("ballogy,external,group");
  const [existingTasks, setExistingTasks] = useState([]);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [search, setSearch] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const [mode, setMode] = useState("all");
  const inputRef = useRef(null);
  const getExistingTask = (e) => {
    setLoading(true);
    let url = "";
    if (skillLevel.length === 0) {
      url = `admin/task?tag=${
        selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_by=${sort}&group_id=${
        params.id
      }&search=${search}`;
    } else {
      url = `admin/task?skill_level=${skillLevel}&tag=${
        selectedCategory === null ? "" : selectedCategory
      }&task_filter=${taskType}&sort_by=${sort}&group_id=${
        params.id
      }&search=${search}`;
    }
    getDataTrainingCoach(url)
      .then((res) => {
        setExistingTasks(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOk = () => {
    setExistingTaskModal(false);
  };
  const handleCancel = () => {
    setExistingTaskModal(false);
  };
  React.useEffect(() => {
    if (existingTaskModal) {
      setLoading(true);
      getExistingTask();
    } else {
      setSelectedCategory(null);
      setExistingTasks((existingTasks) => []);
      setTaskType(true);
      setSort("newest");
      setSkillLevel(null);
    }
  }, [skillLevel, taskType, sort, selectedCategory, existingTaskModal, search]);
  const onChangeSkill = (e) => {
    setSkillLevel(e);
  };
  const onChangeTaskType = (e) => {
    setTaskType(e);
  };
  const onChangeCategory = (e) => {
    inputRef.current.focus({
      cursor: "all",
    });
    setCategoryModal(true);
  };
  const clickedTask = (item) => {
    setEditTaskModal(true);
    setSelectedTask(item);
  };
  const searchTask = (e) => {
    if (e.target.value.length >= 3) {
      setExistingTasks([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setExistingTasks([]);
      setExistingTasks((existingTasks) => []);
      setSearch("");
    }
  };
  const options = [
    {
      value: "beginner",
      label: "Beginner",
    },
    {
      value: "intermediate",
      label: "Intermediate",
    },
    {
      value: "advanced",
      label: "Advanced",
    },
  ];
  const taskOptions = [
    {
      value: "ballogy",
      label: "Ballogy tasks",
    },
    {
      value: "group",
      label: "Only this group's tasks",
    },
    {
      value: "external",
      label: "External tasks",
    },
  ];
  useEffect(()=>{
    if (mode === "all") {
      setTaskType(['ballogy', 'group', 'external'])
    } else {
      setTaskType(["group"]);
    }
  },[mode])
    return (
    <Modal
      className="task-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={existingTaskModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Just This Assignment"}
      cancelText={"Update The Workout Template"}
      width={"699px"}
      footer={false}
    >
      <h1>Add Existing Task</h1>
      <p>Assign an existing task</p>
      <label htmlFor="category">Task category</label>
      <br />
      <Input
        placeholder="Select Category"
        optionFilterProp="children"
        onClick={onChangeCategory}
        suffixIcon={<img src={DownArrow} />}
        ref={inputRef}
        className="category-select search-select-input"
        style={{ height: 38, width: 413, color: "#7e8299" }}
        value={selectedCategory === "" ? "All Tasks" : selectedCategory}
      />
      <div className="tasks-list">
        <h3>Tasks List</h3>
        <Input
          addonBefore={<img src={Search} />}
          placeholder="Search"
          className="search-input"
          onChange={searchTask}
          style={{ marginBottom: "26px", width: 413 }}
        />
        <Checkbox.Group
          options={options}
          defaultValue={["beginner", "intermediate", "advanced"]}
          onChange={onChangeSkill}
          className="custom-checkbox"
        />
        <div style={{ display: "flex", marginBottom: "26px", marginTop: 15 }}>
          {/* <Select
            placeholder="Skill Level"
            onChange={onChangeSkill}
            value={skillLevel}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            options={[
              {
                value: "beginner",
                label: "Beginner",
              },
              {
                value: "intermediate",
                label: "Intermediate",
              },
              {
                value: "advanced",
                label: "Advanced",
              },
            ]}
          />
          <Select
            placeholder="Select task type"
            onChange={onChangeTaskType}
            value={taskType}
            className="select-input"
            suffixIcon={<img src={DownArrow} />}
            options={[
              {
                value: "ballogy",
                label: "Ballogy tasks",
              },
              {
                value: "group",
                label: "Only this group's tasks",
              },
              {
                value: "external",
                label: "External tasks",
              }
            ]}
          /> */}
          <Checkbox.Group
            options={taskOptions}
            onChange={onChangeTaskType}
            defaultValue={
              mode === "all" ? ["ballogy", "external", "group"] : ["group"]
            }
            // value={
            //   mode === "all" ? ["ballogy", "external", "group"] : ["group"]
            // }              
            value={taskType}
            className="custom-checkbox workout-type-checkbox"
            style={{ marginTop: 8 }}
          />
          <Select
            placeholder="Newest"
            onChange={(e) => setSort(e)}
            value={sort}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            style={{ marginLeft: "auto" }}
            options={[
              {
                value: "newest",
                label: "Newest",
              },
              {
                value: "oldest",
                label: "Oldest",
              },
              {
                value: "popular",
                label: "Most Popular",
              },
            ]}
          />
        </div>
        {loading && loading == true ? (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "49%",
            }}
          />
        ) : (
          <div className="task-listing">
            {existingTasks.map((item, index) => {
              let tags = item.tags.split(",");
              return (
                <div
                  className="list"
                  key={index}
                  onClick={() => clickedTask(item)}
                >
                  <div style={{ display: "flex", width: "100%" }}>
                    <Button
                      className="green-tag"
                      style={{ textTransform: "capitalize", margin: 0 }}
                    >
                      {item.skill_level}
                    </Button>
                    {item?.group_id !== parseInt(params.id) &&
                    <h3 style={{ marginLeft: "auto", fontWeight: 400 }}>
                      Assigned:
                      <span style={{ fontWeight: 600 }}>
                        {item?.reused_count}{" "}
                      </span>
                      times
                    </h3>
                  }
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <h3>{item.title}</h3>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="task-added">
                      <span className="time">
                        {item?.score_based_test === true
                          ? "Scored Task"
                          : "Training Task"}
                      </span>
                    </div>
                    <span style={{ margin: "-3px 5px" }}>|</span>
                    <div className="task-added">
                      Added:
                      <span className="time">
                        {" "}
                        {getDateWithoutDay(item.created_at)}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="tags">
                    Tags:
                    {tags.map((item, index) => (
                      <Button className="purple-tag" key={index}>
                        {item}
                      </Button>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {editTaskModal === true && (
        <EditTask
          editTaskModal={editTaskModal}
          setEditTaskModal={setEditTaskModal}
          selectedTask={selectedTask}
          newTaskArray={newTaskArray}
          setNewTaskArray={setNewTaskArray}
          setExistingTaskModal={setExistingTaskModal}
          generalDetail={generalDetail}
        />
      )}
      <CategoryModal
        categoryModal={categoryModal}
        setCategoryModal={setCategoryModal}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categoryType={"task"}
        mode={mode}
        setMode={setMode}
      />
    </Modal>
  );
};

export default ExistingTask;
