import { useContext, useState } from "react";
import { Modal, Row, Col, Button, Progress , Spin, Tooltip } from "antd";
import { UserContext } from "../../../context/user-context";
import Check from "../../../assets/images/check-circle-grey.svg";
import FWDetailModal from "../fw-detail-modal/FW-detail-modal";
import {
  FWDetailContext,
  FWDetailContextProvider,
} from "../../../coach-context/group-context/FW-detail-context";
import AssignedFWModal from "../assigned-fw-modal/assigned-fw-modal";
import { useNavigate } from "react-router-dom";
const FeaturedWorkoutModalPage = ({
  featuredWorkoutModal,
  setFeaturedWorkoutModal,
  selectedCategory,
  setSelectedCategory,
  categoryType,
  allFeaturedWorkouts,
  generalDetail,
  fWLoading,
  setWorkoutDetail,
 setExistingWorkout,
 setSelectedWorkout
}) => {
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const { setSelectedFW, fWDetailModal, setFWDetailModal  , fWAssignedModal,setFWAssignedModal} =
    useContext(FWDetailContext); 
    const navigate = useNavigate();
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);
    function paramsToObject(entries) {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    }
const showAssignedFwDetail = (item) => {
  setSelectedWorkout(item)
  setWorkoutDetail(true);
    setExistingWorkout(false);
  navigate({
    search: `id=${params.id}&tab=workouts&workout_id=${item.id}`,
  });
  // setWorkoutDetail(true),
}
  return (
    <Modal
      className="category-modal fw-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={featuredWorkoutModal}
      //   onOk={handleOk}
      onCancel={() => setFeaturedWorkoutModal(false)}
      okText={"Finish Editing"}
      cancelText={"Cancel"}
      width={"569px"}
      footer={false}
    >
      <h1>Featured Workouts</h1>
      <p>Select a features workout below</p>
      {fWLoading && fWLoading == true ? (
          <Spin
            className={"loader " + (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")}
            size="large"
            style={{
              position: "absolute",
              left: "49%",
              top:"65%"
            }}
          />
        ) : (
      <Row gutter={[14, 14]}>
        {allFeaturedWorkouts.map((item, index) => (
          <Col
            className={"gutter-row"}
            span={24}
            key={index}
            onClick={() => {
              // setSelectedCategory(item.tag);
                            // setFeaturedWorkoutModal(false);
                            setSelectedFW(item.id);

                            item.is_assigned === true ?            
                            showAssignedFwDetail(item)
                              // setFWAssignedModal(true) 
                              : setFWDetailModal(true);
                          }}
          >
            <div
              style={{ backgroundImage: `url(${item.image})` }}
              className={
                "card " + (selectedCategory === item.tag ? "bordered" : "")
              }
            >
              {item.is_assigned && (
                <Button
                  style={{ position: "absolute", top: 10, left: 20 }}
                  icon={<img src={Check} />}
                  className="assigned-btn"
                >
                  {/* //    <img src={Check} />  */}
                  <span>Assigned</span>
                </Button>
              )}
              <div style={{ textAlign: "left", padding: "0 10px 15px"  }}>
              {" "}
                <Tooltip placement="bottom" title={item.title} autoAdjustOverflow>
                {item.title}          </Tooltip>
                
              </div>
              {item.is_assigned && (
                <Progress
                  type="circle"
                  percent={item.completion_percentage}
                  size={48}
                  className={
                    "workout-progress " +
                    (selectedSport === 1
                      ? "orange-progress-circle"
                      : "blue-progress-circle")
                  }
                  strokeWidth={12}
                />
              )}
            </div>
          </Col>
        ))}
      </Row>
        )}
      <FWDetailModal
        fWDetailModal={fWDetailModal}
        setFWDetailModal={setFWDetailModal}
        generalDetail={generalDetail}
      />
      <AssignedFWModal fWAssignedModal={fWAssignedModal} setFWAssignedModal={setFWAssignedModal}/>

    </Modal>
  );
};

const FeaturedWorkoutModal = ({
  featuredWorkoutModal,
  setFeaturedWorkoutModal,
  selectedCategory,
  setSelectedCategory,
  categoryType,
  allFeaturedWorkouts,
  generalDetail,
  fWLoading,
setWorkoutDetail,
 setExistingWorkout,
 setSelectedWorkout
}) => {
  return (
    <FWDetailContextProvider>
      <FeaturedWorkoutModalPage
        featuredWorkoutModal={featuredWorkoutModal}
        setFeaturedWorkoutModal={setFeaturedWorkoutModal}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categoryType={categoryType}
        allFeaturedWorkouts={allFeaturedWorkouts}
        generalDetail={generalDetail}
        fWLoading={fWLoading}
        setWorkoutDetail={setWorkoutDetail}
 setExistingWorkout={setExistingWorkout}
 setSelectedWorkout={setSelectedWorkout}      />
    </FWDetailContextProvider>
  );
};

export default FeaturedWorkoutModal;
