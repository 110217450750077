import { useContext, useEffect, useRef, useState } from "react";
import "./existing-workout.scss";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import { Button, Input, Select, Spin, Radio, Checkbox } from "antd";
import WorkoutModal from "./workout-modal";
import { getDateWithoutDay } from "../../../util/config";
import DownArrow from "../../../assets/images/down-arrow.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  ExistingWorkoutContext,
  ExistingWorkoutContextProvider,
} from "../../../context/existing-workout-context";
import Search from "../../../assets/images/search.svg";
import SelectedWorkoutDetail from "./selected-workout-detail";
import { useNavigate } from "react-router-dom";
import CategoryModal from "./category-modal";
import FeaturedWorkoutModal from "./featured-workouts-modal";
import { UserContext } from "../../../context/user-context";
const ExistingWorkoutPage = ({
  setWorkoutDetail,
  setExistingWorkout,
  generalDetail,
  setSelectedWorkout,
}) => {
  const { selectedSport, color } = useContext(UserContext);
  const navigate = useNavigate();
  const [showAllWorkouts, setShowAllWorkouts] = useState(true);
  const {
    existingWorkouts,
    workoutTasks,
    setWorkoutTasks,
    params,
    setSelectedCategory,
    setUpdateTemplate,
    setSkillLevel,
    setTaskType,
    sort,
    setSort,
    search,
    setSearch,
    setExistingWorkouts,
    loading,
    taskType,
    selectedCategory,
    allFeaturedWorkouts,
    getAllFeatureWorkouts,
    fWLoading,
    skillLevel
  } = useContext(ExistingWorkoutContext);
  const [workoutUpdateModal, setWorkoutUpdateModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [featuredWorkoutModal, setFeaturedWorkoutModal] = useState(false);
  const [mode, setMode] = useState("all");
  const inputRef = useRef(null);
  const showWorkouts = () => {
    setWorkoutDetail(false);
    setExistingWorkout(false);
    navigate({
      search: `id=${params.id}&tab=workouts`,
    });
  };
  const onChangeCategory = (e) => {
    inputRef.current.focus({
      cursor: "all",
    });
    setCategoryModal(true);
  };
  const onChangeSkill = (e) => {
    setExistingWorkout(existingWorkouts=>[])
    setExistingWorkouts([]);
    setSkillLevel(e);
  };
  const onChangeTaskType = (e) => {
    setExistingWorkout(existingWorkouts=>[])
    setExistingWorkouts([]);
    setTaskType(e);
  };
  const searchWorkout = (e) => {
    if (e.target.value.length >= 3) {
      setExistingWorkouts([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setExistingWorkouts((workoutsData) => []);
      setExistingWorkouts([]);
      setSearch("");
    }
  };
  const options = [
    {
      value: "beginner",
      label: "Beginner",
    },
    {
      value: "intermediate",
      label: "Intermediate",
    },
    {
      value: "advanced",
      label: "Advanced",
    },
  ];
  const taskOptions = [
    {
      value: "ballogy",
      label: "Ballogy workouts",
    },
    {
      value: "group",
      label: "Only this group's workouts",
    },
    {
      value: "external",
      label: "External workouts",
    },
  ];
  useEffect(() => {
    if (mode === "all") {
      setTaskType(['ballogy', 'group', 'external'])
    } else {
      setTaskType(["group"]);
    }
  }, [mode]);
  return (
    <div className="existing-workout-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          onClick={() => showWorkouts()}
          style={{ stroke: color, color: color }}
        />
        <h1>Assign an existing workout</h1>
      </div>
      {showAllWorkouts ? (
        <>
          <label htmlFor="category">Workout category</label>
          <br />
          <Input
            placeholder="Select Category"
            optionFilterProp="children"
            onClick={onChangeCategory}
            suffixIcon={<img src={DownArrow} />}
            ref={inputRef}
            className="category-select search-select-input"
            style={{ height: 38, width: 413, color: "#7e8299" }}
            value={
              selectedCategory === ""
                ? "All Workouts"
                : selectedCategory === "featured"
                ? "Featured Worouts"
                : selectedCategory
            }
          />
          <div className="tasks-filter">
            <h3>Workouts</h3>
            <Input
              addonBefore={<img src={Search} />}
              placeholder="Search"
              className="search-input"
              onChange={searchWorkout}
              style={{ marginBottom: "26px", width: 413 }}
            />
            <br />
            <Checkbox.Group
              options={options}
              defaultValue={["beginner", "intermediate", "advanced"]}
              onChange={onChangeSkill}
              className="custom-checkbox"
              disabled={skillLevel === "beginner,intermediate,advanced" && loading === true ? true : false}
            />
            <div
              style={{
                display: "flex",
                marginBottom: "26px",
                marginTop: 15,
                width: 700,
              }}
            >
              {/* <Select
                placeholder="Skill Level"
                optionFilterProp="children"
                suffixIcon={<img src={DownArrow} />}
                className="select-input"
                onChange={onChangeSkill}
                options={[
                  {
                    value: "beginner",
                    label: "Beginner",
                  },
                  {
                    value: "intermediate",
                    label: "Intermediate",
                  },
                  {
                    value: "advanced",
                    label: "Advanced",
                  },
                ]}
              />
              <Select
                placeholder="Select workout type"
                optionFilterProp="children"
                className="select-input"
                suffixIcon={<img src={DownArrow} />}
                onChange={onChangeTaskType}
                options={[
                  {
                    value: "ballogy",
                    label: "Ballogy workouts",
                  },
                  {
                    value: "group",
                    label: "Only this group's workouts",
                  },
                  {
                    value: "external",
                    label: "External workouts",
                  }
                ]}
              /> */}
              <Checkbox.Group
                options={taskOptions}
                onChange={onChangeTaskType}
                defaultValue={
                  mode === "all" ? ["ballogy", "external", "group"] : ["group"]
                }
                // value={
                //   mode === "all" ? ["ballogy", "external", "group"] : ["group"]
                // }
                value={taskType}
                className="custom-checkbox workout-type-checkbox"
                style={{ marginTop: 8 }}
                disabled={taskType === "ballogy,external,group" && loading === true ? true : false}
              />
              <Select
                placeholder="Newest"
                optionFilterProp="children"
                suffixIcon={<img src={DownArrow} />}
                className="select-input"
                style={{ marginLeft: "auto", marginRight: 0 }}
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                defaultValue={sort}
                onChange={(e) => setSort(e)}
                options={[
                  {
                    value: "newest",
                    label: "Newest",
                  },
                  {
                    value: "oldest",
                    label: "Oldest",
                  },
                  {
                    value: "popular",
                    label: "Most Popular",
                  },
                ]}
              />
            </div>
          </div>
          {loading && loading == true ? (
            <Spin
              className={
                "loader " +
                (selectedSport === 1
                  ? "orange-theme-loader"
                  : "blue-theme-loader")
              }
              size="large"
              style={{
                margin: "5% 50%",
              }}
            />
          ) : (
            <div className="tasks-list">
              {existingWorkouts.map((item, index) => {
                let tags = item.tags.split(",");
                return (
                  <div
                    className="list"
                    key={index}
                    onClick={() => {
                      setWorkoutTasks(item);
                      setShowAllWorkouts(false);
                    }}
                  >
                    <div style={{ display: "flex", width: "100%" }}>
                      <Button
                        className="green-tag"
                        style={{ textTransform: "capitalize", margin: 0 }}
                      >
                        {item.skill_level}
                      </Button>
                      {item?.group_id !== parseInt(params.id) &&
                      <h3 style={{ marginLeft: "auto", fontWeight: 400 }}>
                        Assigned:
                        <span style={{ fontWeight: 600 }}>
                          {item?.reused_count}{" "}
                        </span>
                        times
                      </h3>
                      }
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="task-added">
                      Added:
                      <span className="time">
                        {" "}
                        {getDateWithoutDay(item.created_at)}{" "}
                      </span>
                      {/* by */}
                      {/* <span className="name"> {item.group_name}</span> */}
                    </div>
                    <div className="tags">
                      Tags:
                      {tags.length > 0 && !tags.includes("")
                        ? tags.map((item, index) => (
                            <Button
                              className="purple-tag"
                              key={index}
                              style={{ textTransform: "capitalize" }}
                            >
                              {item}
                            </Button>
                          ))
                        : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <SelectedWorkoutDetail
          setShowAllWorkouts={setShowAllWorkouts}
          workoutTasks={workoutTasks}
          workoutUpdateModal={workoutUpdateModal}
          setWorkoutUpdateModal={setWorkoutUpdateModal}
          setNewWorkout={setExistingWorkout}
          generalDetail={generalDetail}
        />
      )}
      <WorkoutModal
        workoutUpdateModal={workoutUpdateModal}
        setWorkoutUpdateModal={setWorkoutUpdateModal}
        setUpdateTemplate={setUpdateTemplate}
        generalDetail={generalDetail}
      />
      <CategoryModal
        categoryModal={categoryModal}
        setCategoryModal={setCategoryModal}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categoryType={"workout"}
        featuredWorkoutModal={featuredWorkoutModal}
        setFeaturedWorkoutModal={setFeaturedWorkoutModal}
        getAllFeatureWorkouts={getAllFeatureWorkouts}
        mode={mode}
        setMode={setMode}
      />
      <FeaturedWorkoutModal
        categoryModal={categoryModal}
        setCategoryModal={setCategoryModal}
        allFeaturedWorkouts={allFeaturedWorkouts}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categoryType={"workout"}
        featuredWorkoutModal={featuredWorkoutModal}
        setFeaturedWorkoutModal={setFeaturedWorkoutModal}
        generalDetail={generalDetail}
        fWLoading={fWLoading}
        setWorkoutDetail={setWorkoutDetail}
        setExistingWorkout={setExistingWorkout}
        setSelectedWorkout={setSelectedWorkout}
      />
    </div>
  );
};
const ExistingWorkout = ({
  setWorkoutDetail,
  setExistingWorkout,
  generalDetail,
  setSelectedWorkout,
}) => {
  return (
    <ExistingWorkoutContextProvider>
      <ExistingWorkoutPage
        setWorkoutDetail={setWorkoutDetail}
        setExistingWorkout={setExistingWorkout}
        generalDetail={generalDetail}
        setSelectedWorkout={setSelectedWorkout}
      />
    </ExistingWorkoutContextProvider>
  );
};
export default ExistingWorkout;
